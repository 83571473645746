import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import CarpetAndUpholsteryProductCustomizer from '@components/product_customizers/carpet_and_upholstery_cleaning'
import BathroomCleaningProductCustomizer from '@components/product_customizers/bathroom_cleaning'
import OvenCleaningProductCustomizer from '@components/product_customizers/oven_cleaning'
import CarUpholsteryProductCustomizer from '@components/product_customizers/car_upholstery_cleaning';
import DisinfectionProductCustomizer from '@components/product_customizers/disinfection';
import LightCleaningProductCustomizer from '@components/product_customizers/light_cleaning';
class ExtraServicesSelector extends React.Component {
    static propTypes = {
        initiallySelectedProductOptions: PropTypes.object,
        optionalServices: PropTypes.arrayOf(PropTypes.string),
        pricelistData: PropTypes.PropTypes.object,
        onSubmit: PropTypes.func,
        serviceName: PropTypes.string,
        onExtraServicesUpdate: PropTypes.func,
        discountPercent: PropTypes.number,
        areExtrasOptional: PropTypes.bool,
        subheadingText: PropTypes.string,
        headingText: PropTypes.string,
    };

    static defaultProps = {
        areExtrasOptional: true,
    };

    static defaultProps = {};

    state = {
        updatedProductOptions: {},
        totalPrice: undefined
    };


    handleSubmit = () => {
        this.props.onSubmit();
    };

    // is valid holds if the selection is ok to continue if it's mandatory
    itemsUpdated = (serviceType, selectedProductOptions, isValid, totalPrice) => {
        var updatedItems = {...this.state.updatedProductOptions, ...selectedProductOptions};
        this.setState({updatedProductOptions: updatedItems, totalPrice: totalPrice}, function() {
            this.props.onExtraServicesUpdate(updatedItems, totalPrice);
        }.bind(this));

    };

    ctaText = () => {
        if (this.state.totalPrice > 0) {
            return i18n.t('form.buttons.proceed');
        } else {
            return i18n.t('form.buttons.skip');
        }
    };

    renderCustomizer = (serviceType, isOptional) => {
        if (serviceType === 'carpet_and_upholstery_cleaning') {
            return (
                <CarpetAndUpholsteryProductCustomizer isOptional={isOptional}
                                                      pricelistData={this.props.pricelistData}
                                                      initiallySelectedProductOptions={this.props.initiallySelectedProductOptions}
                                                      onItemSelectionChange={this.itemsUpdated}
                                                      showExplanation={true}
                                                      discountPercent={this.props.discountPercent}
                                                      key={serviceType}
                />
            )
        }
        if (serviceType === 'bathroom_cleaning') {
            return (
                <BathroomCleaningProductCustomizer isOptional={isOptional}
                                                   pricelistData={this.props.pricelistData}
                                                   initiallySelectedProductOptions={this.props.initiallySelectedProductOptions}
                                                   onItemSelectionChange={this.itemsUpdated}
                                                   showExplanation={true}
                                                   discountPercent={this.props.discountPercent}
                                                   key={serviceType}
                />
            )
        }
        if (serviceType === 'oven_cleaning') {
            return (
                <OvenCleaningProductCustomizer isOptional={isOptional}
                                               pricelistData={this.props.pricelistData}
                                               initiallySelectedProductOptions={this.props.initiallySelectedProductOptions}
                                               onItemSelectionChange={this.itemsUpdated}
                                               showExplanation={true}
                                               discountPercent={this.props.discountPercent}
                                               key={serviceType}
                />
            )
        }
        if (serviceType === 'car_upholstery_cleaning') {
            return (
                <CarUpholsteryProductCustomizer isOptional={isOptional}
                                                pricelistData={this.props.pricelistData}
                                                initiallySelectedProductOptions={this.props.initiallySelectedProductOptions}
                                                onItemSelectionChange={this.itemsUpdated}
                                                showExplanation={true}
                                                discountPercent={this.props.discountPercent}
                                                key={serviceType}
                />
            )
        }
        if (serviceType === 'disinfection') {
            return (
                <DisinfectionProductCustomizer isOptional={isOptional}
                                               pricelistData={this.props.pricelistData}
                                               initiallySelectedProductOptions={this.props.initiallySelectedProductOptions}
                                               onItemSelectionChange={this.itemsUpdated}
                                               showExplanation={true}
                                               discountPercent={this.props.discountPercent}
                                               key={serviceType}
                />
            )
        }
        if (serviceType === 'light_cleaning') {
            return (
                <LightCleaningProductCustomizer isOptional={isOptional}
                                                pricelistData={this.props.pricelistData}
                                                initiallySelectedProductOptions={this.props.initiallySelectedProductOptions}
                                                onItemSelectionChange={this.itemsUpdated}
                                                showExplanation={true}
                                                discountPercent={this.props.discountPercent}
                                                key={serviceType}
                />
            )
        }
    };


    render() {
        return (
            <div className="deep-cleaning-customization">
                <div>
                    <div className="funnel-heading">
                        {this.props.headingText}
                    </div>
                    <div className="funnel-subheading">
                        {this.props.subheadingText}
                    </div>
                </div>

                {_.map(this.props.optionalServices, function (serviceType) {
                    return (
                        this.renderCustomizer(serviceType, this.props.areExtrasOptional)
                    )
                }.bind(this))}

                <div className="form-footer extra-service-form-footer">
                    <button
                        className={"btn btn-primary next-step btn-submit "}
                        onClick={this.handleSubmit}>
                        {this.ctaText()}
                    </button>
                </div>
            </div>
        );
    }
}

export default ExtraServicesSelector;
