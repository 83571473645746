import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CompanyServiceProfileLink from '@components/common/company_service_profile_link'
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import MoneyUtils from '@services/money_utils'

class CompanyBox extends React.Component {
    static propTypes = {
        companyData: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            logo_url: PropTypes.string,
            rating: PropTypes.string,
            is_new_company: PropTypes.bool,
            description_with_locale: PropTypes.object, //Should be in the form locale: String
            events_count: PropTypes.number,
            safety_first: PropTypes.bool,
            promoted: PropTypes.bool,
            previous_choice: PropTypes.bool,
            product: PropTypes.shape({
                visitPrice: PropTypes.string,
                minVisitPrice: PropTypes.string
            }),
            ranking: PropTypes.shape({
                domestina_rank: PropTypes.number,
                price: PropTypes.string,
                bayesian_booking_rating: PropTypes.string,
                has_discount: PropTypes.bool,
                discounted_price: PropTypes.string,
            }),

            onSelectUrl: PropTypes.string
        }),
        showRecommendedBadge: PropTypes.bool,
        showPromotedProfile: PropTypes.bool,
        highlighted: PropTypes.bool,

        onSelect: PropTypes.func,
        selectBtnText: PropTypes.string,
        profileSelectBtnText: PropTypes.string,

        // the current top rank which will determine recommended choice badge
        collectionTopRank: PropTypes.number,

        membershipPercentDiscount: PropTypes.number,
        buyAddressMembership: PropTypes.bool,
        isCurrentAddressMember: PropTypes.bool,
        toggleBuyMembership: PropTypes.func,

    };

    handleSelect = (e) => {
        e.stopPropagation();
        this.props.onSelect(this.props.companyData.id);
    };

    handleProfileSelect = () => {
        this.props.onSelect(this.props.companyData.id);
    };


    visitPrice = () => {
        let realPrice;
        realPrice = parseFloat(this.props.companyData.product.visitPrice)
        let minPrice = this.props.companyData.product.minVisitPrice;

        let price = (minPrice && realPrice < parseFloat(minPrice)) ? parseFloat(minPrice) : realPrice;

        return SHOW_PRICES_WITH_NO_VAT ? price / ((100 + VAT_PERCENT) / 100) : price;
    }

    showMinPrice = () => {
        if(this.props.companyData.product.minVisitPrice){
            let realPrice = parseFloat(this.props.companyData.product.visitPrice);
            let minPrice = parseFloat(this.props.companyData.product.minVisitPrice);
            return realPrice < minPrice;
        } else {
            return false;
        }
    }

    isMembershipDiscountActive = () => {
        return this.props.buyAddressMembership || this.props.isCurrentAddressMember;
    }

    discountedPrice = () => {
        if (this.isMembershipDiscountActive()) {
            return this.visitPrice() * ((100 - this.props.membershipPercentDiscount) / 100)
        } else {
            return this.visitPrice();
        }
    };

    membershipDiscountAmount = () => {
        return this.visitPrice() * ((this.props.membershipPercentDiscount) / 100)
    };

    hasDiscount = () => {
        return this.isMembershipDiscountActive();
    };

    shouldRenderNewBadge = () => {
        return this.props.companyData.is_new_company;
    }

    hasRating = () => {
        return this.props.companyData.rating !== null && this.props.companyData.rating > 0;
    };

    isSafetySearchParticipant = () => {
        return this.props.companyData.safety_first;
    };

    renderNewCompanyBadge = () => {
        var tooltip = (
            <Tooltip id={'tooltip_new_company' + this.props.companyData.id}>
                {i18n.t('funnel.companies.new-provider-tooltip')}
            </Tooltip>
        );

        return (
            <div className="new-company-badge">
                <OverlayTrigger placement="top" overlay={tooltip}>
                    <span>
                        <span className="glyphicon glyphicon-flag" aria-hidden="true"/>
                        {i18n.t('funnel.companies.new-provider')}
                    </span>
                </OverlayTrigger>
            </div>
        );
    };

    renderRecommendedBadge = () => {
        return (
            <div className='recommended-badge'>
                {this.renderRecommendedBadgeOverlay()}
            </div>
        );
    };

    renderPreviousChoiceBadge = () => {
        return (
            <div className='previous-choice-badge'>
                <span className="glyphicon glyphicon-time" aria-hidden="true"/>
                {i18n.t('funnel.companies.badges.previous-choice')}
            </div>
        );
    };

    renderRecommendedBadgeOverlay = () => {
        var tooltip = (
            <Tooltip id={'recommended-badge-' + this.props.companyData.id}>
                {i18n.t('funnel.companies.badges.recommended-choice-tooltip')}
            </Tooltip>
        );

        return (
            <OverlayTrigger placement="bottom" overlay={tooltip}>
                    <span>
                        <span className="glyphicon glyphicon-thumbs-up" aria-hidden="true"/>
                        {i18n.t('funnel.companies.badges.recommended-choice')}
                    </span>
            </OverlayTrigger>
        );
    };

    renderPromotedBadge = () => {
        return (
            <div className='promoted-badge'>
                {this.renderPromotedBadgeOverlay()}
            </div>
        );
    };

    renderPromotedBadgeOverlay = () => {
        var tooltip = (
            <Tooltip id={'promoted-badge-' + this.props.companyData.id}>
                {i18n.t('funnel.companies.badges.promoted-company-tooltip')}
            </Tooltip>
        );

        return (
            <OverlayTrigger placement="bottom" overlay={tooltip}>
                <span>
                    <span className="glyphicon glyphicon-bullhorn" aria-hidden="true"/>
                    {i18n.t('funnel.companies.badges.promoted-company')}
                </span>
            </OverlayTrigger>
        );
    };

    actionButton = () => {
        if (!this.props.selectBtnText) {
            return null;
        }

        if (this.props.onSelect) {
            return (
                <button className={"btn btn-primary cta-button "} onClick={this.handleSelect}>
                    {this.props.selectBtnText}
                </button>
            );
        } else if (this.props.companyData.onSelectUrl) {
            return (
                <a href={this.props.companyData.onSelectUrl} className="btn btn-primary cta-button">
                    {this.props.selectBtnText}
                </a>
            );
        } else {
            return null;
        }
    };

    truncateText = (text) => {
        var length = 150;
        var ending = '...';
        if (typeof text === "undefined") {
            return i18n.t('funnel.companies.no-description');
        }

        if (text.length > length) {
            return text.replace(/<[^>]*>?/gm, '').substring(0, length - ending.length) + ending;
        } else {
            return text;
        }
    };

    priceClass = () => {
        return 'price-container'
    };

    visiblePromoted = () => {
        return this.props.showPromotedProfile && this.props.companyData.promoted;
    };

    visibleRecommendation = () => {
        return this.props.showRecommendedBadge && (this.props.companyData.ranking.domestina_rank === this.props.collectionTopRank) && !this.visiblePromoted();
    };

    render() {
        return (
            <div className={classNames({
                "company-box3": true,
                highlighted: this.props.highlighted,
                "profile-preview-select-box3": true,
                "promoted-profile-funnel": this.visiblePromoted(),
                "recommended-profile-funnel": this.visibleRecommendation()
            })}>

                {(this.visibleRecommendation() || this.visiblePromoted()) &&
                <div className={"hidden-md hidden-lg header-badge " + classNames({'yellow-header-badge': this.visiblePromoted()})}>
                    {this.visibleRecommendation() && this.renderRecommendedBadgeOverlay()}
                    {this.visiblePromoted() && this.renderPromotedBadgeOverlay()}
                </div>}
                <div className="row">
                    <div className="col-md-2 visible-md visible-lg company-logo-container">
                        <img className="company-logo" src={this.props.companyData.logo_url}/>
                    </div>
                    <div className="col-md-5 col-xs-12">
                        <div className="company-name">
                            <CompanyServiceProfileLink
                                linkText={this.props.companyData.name}
                                ctaButtonText={this.props.profileSelectBtnText}
                                onCtaButtonClick={this.handleProfileSelect}
                                companyData={this.props.companyData}/>

                        </div>
                        <div className="visible-md visible-lg description-container">
                            {this.props.companyData.description_with_locale && this.props.companyData.description_with_locale[i18n.locale] &&
                            <div
                                dangerouslySetInnerHTML={{__html: this.truncateText(this.props.companyData.description_with_locale[i18n.locale])}}/>}
                            <div>
                                <CompanyServiceProfileLink
                                    linkText={i18n.t('funnel.companies.view-full-profile')}
                                    ctaButtonText={this.props.profileSelectBtnText}
                                    onCtaButtonClick={this.handleProfileSelect}
                                    companyData={this.props.companyData}/>
                            </div>
                        </div>
                        <div className="badges-container visible-md visible-lg">
                            {this.visibleRecommendation() && this.renderRecommendedBadge()}
                            {this.visiblePromoted() && this.renderPromotedBadge()}
                            {this.shouldRenderNewBadge() && this.renderNewCompanyBadge()}
                            {this.props.companyData.previous_choice && this.renderPreviousChoiceBadge()}
                            {this.isSafetySearchParticipant() &&
                            <div className='visible-md visible-lg'>
                                {this.renderSafetyFirstBadge()}
                            </div>}
                        </div>
                    </div>

                    <div className="col-md-2 col-xs-12">
                        <div className="rating-and-price-container">
                            <div className="rating-container">
                                {this.hasRating() && <div className={classNames({"rating-data": true, "no-rating": !this.hasRating()})}>
                                    <span className="glyphicon glyphicon-star" aria-hidden="true"/>
                                    {this.props.companyData.rating}
                                </div>}

                                {this.shouldRenderNewBadge() &&
                                <div className={'visible-xs visible-sm'}>
                                    {this.renderNewCompanyBadge()}
                                    <div className="clearer"></div>
                                </div>}
                            </div>
                            {this.hasRating() &&
                            <div className="out-of-visits-container">
                                <CompanyServiceProfileLink
                                    linkText={i18n.t('funnel.companies.out-of-visits', {events_count: this.props.companyData.events_count})}
                                    ctaButtonText={this.props.profileSelectBtnText}
                                    onCtaButtonClick={this.handleProfileSelect}
                                    companyData={this.props.companyData}/>
                            </div>}
                            <div className={'visible-xs visible-sm'}>
                                {this.props.companyData.product &&
                                <div className={classNames({'discounted-price': this.props.isCurrentAddressMember}) + " company-price-container"}>
                                    {MoneyUtils.price(this.discountedPrice())}
                                    {SHOW_PRICES_WITH_NO_VAT && <span className="vat-info">({i18n.t('funnel.companies.excl-vat')})</span>}
                                </div>}
                                <div className="clearer"></div>

                                {this.showMinPrice() &&
                                <div className={'min-price-container'}>
                                    *{i18n.t('funnel.companies.min-visit-price')}
                                </div>}
                                <div className="clearer"></div>


                                <div className={'possible-discount-container'}>
                                    {this.props.isCurrentAddressMember && MoneyUtils.price(this.visitPrice()) }
                                </div>
                            </div>
                        </div>
                        <div className="mobile-badges-container hidden-md hidden-lg">
                            {this.props.companyData.previous_choice && this.renderPreviousChoiceBadge()}
                            <div className="clearer"></div>
                        </div>
                    </div>

                    <div className="col-md-3 col-xs-12">
                        <div className={'desktop-price-container visible-md visible-lg'}>
                            <div className={'possible-discount-container'}>
                                {this.props.isCurrentAddressMember && MoneyUtils.price(this.visitPrice()) }
                            </div>

                            {this.props.companyData.product &&
                            <div className={classNames({'discounted-price': this.props.isCurrentAddressMember}) + ' desktop-company-price-container'}>
                                {MoneyUtils.price(this.discountedPrice())}
                                {SHOW_PRICES_WITH_NO_VAT && <span className="vat-info">({i18n.t('funnel.companies.excl-vat')})</span>}
                            </div>}
                            <div className="clearer"></div>

                            {this.showMinPrice() &&
                            <div className={'desktop-min-price-container'}>
                                *{i18n.t('funnel.companies.min-visit-price')}
                            </div>}
                            <div className="clearer"></div>



                        </div>

                        <div className="cta-container">
                            {this.actionButton()}
                        </div>
                        <div className="company-box-ddc-checkbox-container">
                            {this.props.isCurrentAddressMember && <span>{i18n.t('funnel.address-membership.membership-included', {percent: ADDRESS_MEMBERSHIP_DISCOUNT_PERCENT})}</span>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyBox;
